<template>
  <div class="mt-3 table-responsive">
    <table class="table">
      <thead>
        <tr>
          <th
            scope="col"
            class="bt-none bb-none text-white-50 text-center font-weight-normal pl-0 pr-0 pl-sm-2 pr-sm-2"
            width="30"
          >
            {{ $t("other.p") }}
          </th>
          <th
            scope="col"
            class="bt-none bb-none text-white-50 font-weight-normal"
          >
            {{ $t("other.team") }}
          </th>
          <th
            scope="col"
            class="bt-none bb-none text-white-50 text-center font-weight-normal pl-0 pr-0 pl-sm-2 pr-sm-2"
          >
            {{ $t("other.g") }}
          </th>
          <th
            scope="col"
            class="bt-none bb-none text-white-50 text-center font-weight-normal pl-0 pr-0 pl-sm-2 pr-sm-2"
          >
            {{ $t("other.w") }}
          </th>
          <th
            scope="col"
            class="bt-none bb-none text-white-50 text-center font-weight-normal pl-0 pr-0 pl-sm-2 pr-sm-2"
          >
            {{ $t("other.l") }}
          </th>
          <th
            scope="col"
            class="bt-none bb-none text-white-50 text-center font-weight-normal pl-0 pr-0 pl-sm-2 pr-sm-2"
          >
            +/-
          </th>
          <th
            scope="col"
            class="bt-none bb-none text-white-50 font-weight-normal pl-0 pr-0 pl-sm-2 pr-sm-2"
          >
            {{ $t("other.points") }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="g in group" :key="g.ID">
          <td class="align-middle text-center">
            <span class="align-middle game-score text-white">
              {{ g.place }}
            </span>
          </td>
          <td class="align-middle pt-2 pb-2">
            <div class="d-flex align-items-center">
              <div class="pr-2 pr-lg-3">
                <img
                  :src="g.team.filetime ? ($url+'upload/images/teams/'+ g.team.id_team_sl +'/100x100.png?v='+ g.team.filetime) : '/img/icons/stub.png'"
                  width="32"
                  height="32"
                  class="rounded-sm"
                />
              </div>
              <div class="align-self-center">
                <div class="small text-white" v-if="$i18n.locale === 'ru'">
                  {{ g.team.team_title }}
                </div>
                <div class="small text-white" v-else>
                  {{ g.team.team_title_eng }}
                </div>
                <div
                  class="small d-none d-sm-block text-white-50"
                  v-if="$i18n.locale === 'ru'"
                >
                  {{ g.team.player_1_title }}, {{ g.team.player_2_title }}
                </div>
                <div class="small d-none d-sm-block text-white-50" v-else>
                  {{ g.team.player_1_title_eng }},
                  {{ g.team.player_2_title_eng }}
                </div>
              </div>
            </div>
          </td>
          <td class="align-middle text-center">
            <span class="align-middle game-score text-white">
              {{ g.number_of_matches }}
            </span>
          </td>
          <td class="align-middle text-center">
            <span class="align-middle game-score text-white">
              {{ g.winning_matches }}
            </span>
          </td>
          <td class="align-middle text-center">
            <span class="align-middle game-score text-white">
              {{ g.lost_matches }}
            </span>
          </td>
          <td class="align-middle text-center">
            <span class="align-middle game-score text-white text-nowrap">
              {{ g.goals_scored }}-{{ g.goals_missed }}
            </span>
          </td>
          <td class="align-middle">
            <span class="align-middle game-score text-white">
              {{ g.poinds }}
            </span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: "TourGroup",
  props: ["group", "dataSport"]
};
</script>

<style scoped>
.game-score {
  font-size: 14px;
}
/*
.table > tbody > tr:last-child { background-color:rgba(255, 0, 0, 0.28); }
 */
</style>
